import React from 'react'

function DevelopmentService() {
    return (
        <section className="fantasy_area fa_2 fa_3">
            <div className="auto-container">
                <div className="sec-title centered">
                    <h2>Why Choose Us?</h2>
                    <div className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's <br /> standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                    </div>
                </div>
                <div className="row fantasy_inner mt-5 justify-content-md-center">
                    <div className="col-xl-12 row fantasy_items">
                        <div className="col-md-6" >
                            <div className="fantasy">
                                <span className="icons"><i className="fal fa-phone-laptop"></i></span>
                                <a href="javascript:void(0)">React Web App Development</a>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's <br /> standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                            </div>
                        </div>
                        <div className="col-md-6"  >
                            <div className="fantasy fantasy_2">
                                <span className="icons"><i className="fal fa-code"></i></span>
                                <a href="javascript:void(0)">SPA Development</a>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's <br /> standard dummy text ever since the 1500s, when an unknown printer took a galley</p>

                            </div>
                        </div>
                        <div className="col-md-6"  >
                            <div className="fantasy fantasy_3">
                                <span className="icons"><i className="fab fa-react"></i></span>
                                <a href="javascript:void(0)">Migration to React</a>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's <br /> standard dummy text ever since the 1500s, when an unknown printer took a galley</p>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fantasy fantasy_4">
                                <span className="icons"><i className="fal fa-user-headset"></i></span>
                                <a href="javascript:void(0)">Ongoing Support</a>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's <br /> standard dummy text ever since the 1500s, when an unknown printer took a galley</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DevelopmentService
