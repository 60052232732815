import React, { useState } from 'react';
import Background12 from './asset/images/icons/icon-1.png'
import Background14 from './asset/images/background/pattern-19.png'
import Background15 from './asset/images/background/pattern-1.png'
import ContactImg from './asset/images/resource/contact.svg'



function Contact() {
    const formSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <section className="contact-section" id="Contact_Us">
            {/* Contact Section  */}
            <div className="pattern-layer" style={{ backgroundImage: `url(${Background14})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Background15})` }}></div>
            <div className="icon-layer-two" style={{ backgroundImage: `url(${Background12})` }} ></div>
            <div className="icon-layer-three" style={{ backgroundImage: `url(${Background12})` }}></div>
            <div className="auto-container">
                <div className="row clearfix align-items-center">

                    <div className="info-column col-lg-5 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="icon-layer" style={{ backgroundImage: `url(${Background12})` }}></div>
                            <div className="image">
                                <img src={ContactImg} alt="" />
                            </div>
                            <ul className="contact-list">
                                <li>
                                    <i className="fal fa-map-marker"></i>
                                    <span>2976 Edwards Street Rocky Mount, NC 27804</span>
                                </li>
                                <li>
                                    <i className="fal fa-mobile-android"></i>
                                    <a href="tel:+911324567890">+91-132-456-7980</a>
                                </li>
                                <li>
                                    <i className="fal fa-envelope-open"></i>
                                    <a href="mailto:Supports@info.com">Supports@info.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="form-column col-lg-7 col-md-12 col-sm-12 form_z">
                        <div className="inner-column">
                            <div className="color-layer"></div>

                            <div className="sec-title">
                                <h2> Tell Us About  Your Project </h2>
                            </div>

                            <div className="default-form">
                                <form onSubmit={(e) => formSubmit(e)} >
                                    <div className="row clearfix">
                                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                            <input type="text" placeholder="First Name *" required />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                            <input type="text" placeholder="Last Name *" required />
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <input type="email" placeholder="Email *" required />
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <input type="text" placeholder="Subject *" required />
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <textarea placeholder="Enquiry *"></textarea>
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <button type="submit" className="theme-btn btn-style-one"><span className="txt">Submit</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Contact Section  */}
        </section>

    )
}

export default Contact
