import React from 'react'
import DevopmentImg from './asset/images/resource/devopment.gif'
import ProjectImg from './asset/images/icons/project-initiation.svg'
import AnalysisImg from './asset/images/icons/analysis.svg'

function ProcessService() {
    return (
        <section className="fantasy_area process_service" id="Process">
            <div className="auto-container">
                <div className="sec-title centered">
                    <h2>Scrape off Hurdles – React JS <br /> Development Process</h2>
                    <div className="text mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's <br /> standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                    </div>
                </div>
                <div className="row fantasy_inner mt-5 align-items-center">
                    <div className="col-xl-4 col-lg-4 col-md-12">
                        <div className="devopment_img"><img src={DevopmentImg} alt="Devopment Images" /> </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 fantasy_items">
                        <div className="col-md-12" >
                            <div className="fantasy fantasy-circle-style purple-hover">
                                <div class="dot-circle">
                                    <div class="effect-circle"></div>
                                    <div class="main-circle">
                                        <div class="circle-bg">
                                            <i class="fad fa-business-time"></i>
                                        </div>
                                    </div>
                                </div>
                                <a href="javascript:void(0)">Business Consultation</a>
                                <p>React enables us to reuse code for our applications with its powerful composition model. We design new and reusable components and reassemble existing ones in a variety of ways.</p>
                            </div>
                        </div>
                        <div className="col-md-12" data-wow-delay="0.2s" >
                            <div className="fantasy fantasy-circle-style green-hover">
                                <div class="dot-circle">
                                    <div class="effect-circle"></div>
                                    <div class="main-circle">
                                        <div class="circle-bg">
                                            <img src={ProjectImg} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <a href="javascript:void(0)">Project Initiation</a>
                                <p>React enables us to reuse code for our applications with its powerful composition model. We design new and reusable components and reassemble existing ones in a variety of ways.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 fantasy_items">
                        <div className="col-md-12" data-wow-delay="0.6s" >
                            <div className="fantasy fantasy-circle-style green-hover">
                                <div class="dot-circle">
                                    <div class="effect-circle"></div>
                                    <div class="main-circle">
                                        <div class="circle-bg">
                                            <img src={AnalysisImg} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <a href="javascript:void(0)">Project Analysis</a>
                                <p>React enables us to reuse code for our applications with its powerful composition model. We design new and reusable components and reassemble existing ones in a variety of ways.</p>

                            </div>
                        </div>
                        <div className="col-md-12" data-wow-delay="0.4s" >
                            <div className="fantasy fantasy-circle-style purple-hover">
                                <div class="dot-circle">
                                    <div class="effect-circle"></div>
                                    <div class="main-circle">
                                        <div class="circle-bg">
                                            <i class="fal fa-truck-loading"></i>
                                        </div>
                                    </div>
                                </div>
                                <a href="javascript:void(0)">Execution and Delivery</a>
                                <p>React enables us to reuse code for our applications with its powerful composition model. We design new and reusable components and reassemble existing ones in a variety of ways.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProcessService
